import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from "axios";

import { 
    FaWhatsapp
  } from "react-icons/fa";

function addRegister({link, profileData}) {
    let urlBase = 'https://api.gridzzy.com';
    // let urlBase = 'http://192.168.100.224:8000';
    var details = {
        username: profileData.usernameShow.toString().toLowerCase(),
        type_action: 'show_link_service_whatsapp',
        modelObject: 'link',
        idModelObject: link.id,
        description: link.title,
        device: 'movil'
    };
    
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            "Authorization": "Token 709fc5a926fe203731816df78739a87a40e6fc02"
        },
    };

    axios.post(urlBase+'/add-register-action-login/', formBody.join("&"), config);
}

function ContentImage({link, profileData}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        addRegister(link, profileData);
    };
    return <div style={{ flex: 4, alignItems: 'center', justifyContent: 'center' }}>
                {link.urlImage.length > 5 &&
                    <div>
                        <Image
                            onClick={handleShow}
                            src={link.urlImage}
                            alt=""
                            width='100%'
                            style={{ borderRadius: 5, objectFit: 'cover' }}
                        />

                        <Modal show={show} onHide={handleClose} style={{paddingTop: '15%'}} >
                            <Image
                                onClick={handleShow}
                                src={link.urlImage}
                                alt=""
                                width='100%'
                                style={{ borderRadius: 5, objectFit: 'cover' }}
                            />
                            <Button style={{background: '#ef214a', borderRadius: 50, width: 50, position: 'absolute', border: 'none', padding: 10, top: -20, right: 0}} onClick={handleClose}>
                                X
                            </Button>
                        </Modal>
                    </div>
                    
                }

            </div>
}

function TextProduct({link, profileData, position}){
    return <div style={{ flex: 8, justifyContent: position, alignItems: position, justifyItems: position, marginBottom: 35, flexDirection: 'column', display: 'flex', padding: 10 }}>
                <span style={{ fontSize: 15, textAlign: position,  color: profileData.colorTextProfile, flex: 1 }}>
                    {link.title}
                </span>
                <hr style={{color: profileData.colorTextProfile}}></hr>
                {link.description&&
                    <span style={{ color: profileData.colorTextProfile, fontSize: 12, flex: 1 }}>{link.description}</span>
                }

                {(link.price !== 0 && link.price !== '') &&
                    <span style={{ width: '100%', color: profileData.colorTextProfile, fontSize: 26, fontWeight: 'bold', flex: 1 }}>$ {link.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                }
                
                {link.numberContact.length > 4 &&
                    <div style={{flex: 2, justifyItems: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'row', display: 'flex', marginTop: 5}}>

                            {position==='right'&&
                                <div style={{flex: 1}}></div>
                            }
                            
                            <div style={{ borderRadius: 4, padding: 5, alignItems: 'center', flex: 2, background: profileData.colorTextProfile }}>
                                <a onClick={()=>addRegister(link, profileData)} style={{ fontSize: 15, justifyItems: 'center' }} target='_blank' rel="noopener noreferrer" href={"https://api.whatsapp.com/send?text=Hola " + link.menuGrid_object.user_object.first_name + ", Me interesa el producto " + link.title + "&phone=" + link.profileCountry + link.numberContact}> 
                                    <FaWhatsapp size={25} color={profileData.colorPersonBackground} /> 
                                        <span style={{ color: profileData.colorPersonBackground, paddingLeft: 5, paddingRight: 5 }}>Hacer Pedido
                                        </span>
                                </a>

                            </div>


                            {position==='left'&&
                                <div style={{flex: 1}}></div>
                            }

                    </div>
                }

                {(link.limitPoints !== 0 && link.limitPoints !== '') &&
                    <div style={{ marginTop: 10 }}>
                        <span style={{ color: profileData.colorTextProfile, fontSize: 13 }}>Redímelo con{' '}</span>
                        <span title={"Con " + link.limitPoints + " Gridpoints puedes reclamarlo"} style={{  borderRadius: 50, background: profileData.colorTextProfile, fontSize: 13, color: profileData.colorPersonBackground, padding: 8, marginTop: 10 }}>
                            {link.limitPoints}
                        </span>
                        <span style={{ color: profileData.colorTextProfile, fontSize: 13 }}>{' '}Gridpoints</span>
                    </div>
                }

            </div>
}

function OptionOne({ link, countIdAltr, profileData }) {


    if(countIdAltr % 2 === 0){
    
        return (
            <div style={{ width: '100%', textAlign: "right", flexDirection: 'row', display: 'flex' }}>

                <ContentImage link={link} profileData={profileData} />

                <TextProduct link={link} profileData={profileData} position="right"/>

            </div>
    
        );

        }
        return (
            <div style={{ width: '100%', textAlign: 'left', flexDirection: 'row', display: 'flex' }}>

                <TextProduct link={link} profileData={profileData} position="left"/>

                <ContentImage link={link} profileData={profileData}/>

            </div>



  );
}

export default OptionOne;
