import React, { Component, Fragment } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';

import './assets/brochure.css';

import OptionOne from './components/OptionOne';


import { 
        FaWhatsapp, 
        // FaYoutube, 
        // FaTwitter, 
        // FaFacebook, 
        // FaInstagram, 
        // FaGlobe, 
        FaMailBulk,
        FaHamburger,
        // FaLink
      } from "react-icons/fa";

import { IoIosRestaurant, IoIosBeer, IoIosWine, IoIosPie, IoIosIceCream, IoIosNutrition, IoIosPizza, IoIosDesktop } from "react-icons/io";

import { GiJewelCrown, GiClothes } from "react-icons/gi";

import { FcAutomotive, FcMultipleSmartphones, FcServices, FcCalendar, FcBinoculars, FcShop, FcCloseUpMode } from "react-icons/fc";

// import { SiNetflix, SiWechat, SiPinterest, SiTelegram, SiTwitch, SiGithub, SiMessenger, SiTinder, SiWix, SiLine, SiSnapchat, SiDiscord } from "react-icons/si";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import MenuOptionFooter from './components/MenuOptionsFooter';


class Brochure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: {},
      isLoading: true,
      errorMessage: null,
      listMenuGrid: {},
      links: {},
      username: this.props.match.params.user,
      tabCurrent: '',
      isWeb: true,
      tokenTemp: '',
      positionY: 0
    };
    
  }


  onBackButtonEvent(e) {
    
    e.preventDefault();
  }

  componentDidMount() {
    this.getProfileData();
    window.addEventListener('scroll', this.listenToScroll);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }



  getProfileData() {
    
    axios
      .get(`https://api.gridzzy.com/get-list-menu-web/${this.state.username}`,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(response => {
          if(this.props.location.search){
            let searchs = this.props.location.search.replace('?','');
            if(searchs.split('&')[1]){
                this.setState({isWeb: searchs.split('&')[0].split('=')[1], tokenTemp: searchs.split('&')[1].split('=')[1]});
            }else{
              this.setState({isWeb: searchs.split('=')[1]});
            }
          }
          for (let i = 0; i < response.data.menuGrid.length; i++) {
            this.setState({tabCurrent: response.data.menuGrid[i].title});
            this.changeTabSelected(response.data.menuGrid[i].title);
            break;
            
          }
          this.setState({ 
            profileData: response.data, 
            isLoading: false, 
            listMenuGrid: response.data.menuGrid,
            links: response.data.links });
        }
        )
      .catch(error => {
        this.setState({ errorMessage: "El usuario no existe en nuestra base de datos", isLoading: false });
      }
      );
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
  
    this.setState({
      positionY: scrolled,
    })
  }

  changeTabSelected = (tab) => {
    this.setState({tabCurrent: tab});
  }

  render() {
    const { profileData, listMenuGrid, links, isLoading, errorMessage, tabCurrent, positionY } = this.state;

    let content;
    let firstTitle = tabCurrent;
    if (isLoading) {
      content = <div style={{height: '100%', width: '100%'}}>
                  <Spinner animation="border" role="status" style={{marginTop: '50%', marginLeft: '46%'}}>
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                </div>
    } else {
      if (errorMessage) {
        content = <p>{errorMessage}</p>;
      } else {
        content = (
          <div style={{height: '100%', marginBottom: 10}}>
            
          <div className="content-all">
         
            


            <Tab.Container defaultActiveKey={firstTitle} className="content-tabs-menu" transition={false}>

            <div className={listMenuGrid.length<=3?'tabs-menu-top-center':'tabs-menu-top'}>
                {listMenuGrid!==null &&
                  listMenuGrid.map(menu =>{
                      return (
                        <Nav.Link className={(menu.title===tabCurrent ? 'tabs-menu-top-selected':'')} 
                          style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: 20, paddingRight: 20}} 
                          key={menu.title} eventKey={menu.title} onSelect={(tab)=>{this.changeTabSelected(tab);}}>
                            {menu.title==="Correos"&&
                              <FaMailBulk color="#F2A519" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Servicios"&&
                              <FcServices color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Menú"&&
                              <IoIosRestaurant color="#ADA696" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Licor"&&
                              <IoIosBeer color="#F28E1C" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Bebidas"&&
                              <IoIosWine color="#5D292F" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Calendar"&&
                              <FcCalendar color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Postres"&&
                              <IoIosPie color="#45322E" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Helados"&&
                              <IoIosIceCream color="#FDE39F" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Ensaladas"&&
                              <IoIosNutrition color="#EB5111" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Hamburguesas"&&
                              <FaHamburger color="#E67B12" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Pizzas"&&
                              <IoIosPizza color="#F7A00F" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Joyas"&&
                              <GiJewelCrown color="#C9BE68" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Flores"&&
                              <FcCloseUpMode color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Ropa"&&
                              <GiClothes color="#997DC8" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Accesorios"&&
                              <FcBinoculars color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Autos"&&
                              <FcAutomotive color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Teléfonos"&&
                              <FcMultipleSmartphones color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Mercado"&&
                              <FcShop color="#c9c9c9" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }
                            {menu.title==="Computadores"&&
                              <IoIosDesktop color="#086DBE" size={40} style={{display: positionY>0 ? 'none': 'block'}} />
                            }


                            
                            


                            <div style={{paddingTop: 5}}>{menu.title}</div>
                          </Nav.Link>
                      ) 
                  })
                }
            </div>
              <br></br><br></br><br></br>
            <Tab.Content>
              {listMenuGrid!==null &&
              listMenuGrid.map(menu =>{
                let countIdAltr = 0;
                  return(
                    <Tab.Pane key={menu.id} eventKey={menu.title} className="tabs-content container-items-tabs" style={{background: profileData.colorPersonBackground}}>
                      <h1 style={{textAlign: 'center', padding: 15, color: profileData.colorTextProfile}}>{menu.title}</h1>
                      {(links)&&
                        links.map(link => {
                          
                          countIdAltr = countIdAltr+1;
                         
                          if(link.menuGrid_object.id===menu.id){

                            var listHours = [];
                            if(menu.social_object.typeSocial==='CA'){
                              var description = link.description.split("&");
                              for(var i in description){
                                listHours.push(JSON.parse('{"id": '+i+','+description[i]+'}'));
                              }
                            }
                           


                            
                            return(
                              <div key={link.id}>
                                      
                                      <div key={link.id} className="content-items-tabs" style={{ alignItems: 'center',  maxWidth: 450}}> 
                                       
  
                                        <div className="content-links">



                                          {menu.social_object.typeSocial==='EM'&&
                                            <a target='_blank' rel="noopener noreferrer" href={"mailto:"+link.usernameSocial} style={{color: "#cccccc", width: '100%', padding: 5}}> 
                                              <div style={{ paddingLeft: 10, lineHeight: 1, textAlign: 'center', width: '100%', justifyItems: 'center',}}>
                                                <span style={{fontSize: 20 }}>{link.title}</span><br></br>
                                                <span style={{fontSize: 12}}>{link.usernameSocial}</span>
                                              </div>
                                            </a>
                                          }






                                          {menu.social_object.typeSocial==='CA'&& 
                                            <div style={{color: "#656565", width: '100%', padding: 5}}> 
                                              <div style={{ paddingLeft: 10, lineHeight: 1, width: '100%', justifyItems: 'center', textAlign: 'center'}}>
                                                <span style={{fontSize: 20 }}>{link.title}</span><br></br>
                                                <span style={{color: '#959595'}}>(Horas disponibles)</span>
                                                <div style={{maxHeight: 200, overflowY: 'scroll'}}>
                                                    {listHours.map(hour => {
                                                      if(hour.status){
                                                        return (
                                                          <a key={hour.id} target='_blank' rel="noopener noreferrer" href={"https://api.whatsapp.com/send?text=Hola "+link.menuGrid_object.user_object.first_name+", Quisiera reservar contigo el "+link.title+" a las "+hour.hour+"&phone="+link.profileCountry+link.numberContact} style={{color: '#25d366'}}> {hour.hour} <FaWhatsapp size={25} color="#25d366" /> Reservar<br></br></a>
                                                        )
                                                      }else{
                                                        return null
                                                      }
                                                    })}
                                                  </div>
                                              </div>
                                            </div>
                                          }


                                          {(menu.social_object.typeSocial==='SE' 
                                            || menu.social_object.typeSocial==='HA' 
                                            || menu.social_object.typeSocial==='PI'
                                            || menu.social_object.typeSocial==='CM'
                                            || menu.social_object.typeSocial==='LI'
                                            || menu.social_object.typeSocial==='BI'
                                            || menu.social_object.typeSocial==='AU'
                                            || menu.social_object.typeSocial==='TL'
                                            || menu.social_object.typeSocial==='PS'
                                            || menu.social_object.typeSocial==='HE'
                                            || menu.social_object.typeSocial==='EN'
                                            || menu.social_object.typeSocial==='JO'
                                            || menu.social_object.typeSocial==='FL'
                                            || menu.social_object.typeSocial==='RO'
                                            || menu.social_object.typeSocial==='AC'
                                            || menu.social_object.typeSocial==='MR'
                                            || menu.social_object.typeSocial==='PT'
                                            )&&
                                              <div style={{ justifyItems: 'center', textAlign: 'center', paddingBottom: 10, width: '100%', background: profileData.colorPersonBackground}}>
                                                
                                                <OptionOne link={link} countIdAltr={countIdAltr} profileData={profileData}></OptionOne>
                                                
                                              </div>
                                          } 

                                        </div>
  
                                      </div>
                                      
                                    </div>
                            )
                          
                          }else{
                              return null;
                          }
                        })
                      } 
                    </Tab.Pane>
                  )
              })
            }
            </Tab.Content>


            </Tab.Container>

          </div>

          {this.state.isWeb!=='false'&&
            <MenuOptionFooter is_show_modal_start={profileData.is_show_modal_start} title_ventana_emergente={profileData.title_ventana_emergente} description_ventana_emergente={profileData.description_ventana_emergente} url_image_ventana_emergente={profileData.url_image_ventana_emergente} url_image_profile={profileData.imageProfile} username={profileData.usernameShow} background={profileData.colorPersonBackground} colorText={profileData.colorTextProfile}></MenuOptionFooter>  
          }

          </div>
        );
      }
    }

    return <Fragment>
              {content}
            </Fragment>;
  }
}

export default Brochure;
