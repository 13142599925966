import React, { Component, Fragment } from "react";
import axios from "axios";
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './assets/brochure.css';


import { FaWhatsapp, FaEnvelope, FaMapMarkedAlt } from "react-icons/fa";

import MenuOptionFooter from "./components/MenuOptionsFooter";


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: {},
      isLoading: true,
      errorMessage: null,
      username: this.props.match.params.user,
      isWeb: true,
      tokenTemp: '',
      imageBrochure: '',
      setShowModalProfile: false
    };
    
  }


  onBackButtonEvent(e) {
    
    e.preventDefault();
  }

  componentDidMount() {
    this.getProfileData();
    this.getImageBrochure();
  }


  getImageBrochure(){
    axios
      .get(`https://api.gridzzy.com/get-list-menu-web-brochure/${this.state.username}`,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(response => {
          this.setState({imageBrochure: response.data.image});
          // triggerBase64Download('data:image/png;base64, '+response.data.image, this.state.profileData.usernameShow);
        }).catch(error => {
          alert("Ocurrió un error, inténtalo de nuevo");
        });
  }

  handleClose = () => {
    this.setState({setShowModalProfile: !this.state.setShowModalProfile});
  }


  getProfileData() {
    
    axios
      .get(`https://api.gridzzy.com/get-user-profile-web/${this.state.username}`,{
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(response => {
          if(this.props.location.search){
            let searchs = this.props.location.search.replace('?','');
            if(searchs.split('&')[1]){
                this.setState({isWeb: searchs.split('&')[0].split('=')[1], tokenTemp: searchs.split('&')[1].split('=')[1]});
            }else{
              this.setState({isWeb: searchs.split('=')[1]});
            }
          }
          this.setState({ 
            profileData: response.data.profile, 
            isLoading: false
          });
        }
        )
      .catch(error => {
        this.setState({ errorMessage: "El usuario no existe en nuestra base de datos", isLoading: false });
      }
      );
  }

 
  render() {
    const { profileData, isLoading, errorMessage, imageBrochure } = this.state;
    let content;
 
    if (isLoading) {
      content = <div style={{height: '100%', width: '100%'}}>
                  <Spinner animation="border" role="status" style={{marginTop: '50%', marginLeft: '46%'}}>
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                </div>
    } else {
      if (errorMessage) {
        content = <p>{errorMessage}</p>;
      } else {
        content = (
          <div style={{height: '100vh', marginBottom: 10, background: '#f2f2f2'}}>
          <div className="content-all" style={{background: profileData.background_profile, paddingTop: 20}}>
            <div className="content-info-profile">
                      

                      <div className="content-info-profile-name">
                        <h3 className="" style={{color: profileData.color_text_profile}}>
                        {profileData.user_object.first_name} {profileData.user_object.last_name}<br></br>
                        <span className="" style={{color: profileData.color_text_profile, fontSize: 14}}>
                            @{profileData.usernameShow} 
                          </span>
                        </h3>
                       
                      </div>


                      <div className="content-image-profile-brochure">
                        <Image
                          width="100"
                          src={profileData.url_image_profile}
                          className="img-profile"
                          alt=""
                          roundedCircle
                          onClick={this.handleClose}
                        />

                        <Modal show={this.state.setShowModalProfile} onHide={this.handleClose} style={{paddingTop: '15%'}} >
                            <Image
                              width="100%"
                              src={profileData.url_image_profile}
                              className="img-profile"
                              alt=""
                              style={{borderRadius: 8}}
                            />
                            <Button style={{background: '#ef214a', borderRadius: 50, width: 50, position: 'absolute', border: 'none', padding: 10, top: -20, right: 0}} onClick={this.handleClose}>
                                X
                            </Button>
                        </Modal>


                      </div>

            </div>
          </div>


            <div className="content-white-info" style={{background: 'white', paddingTop: 70, borderTopRightRadius: 60, borderTopLeftRadius: 60, height: '90vh', marginTop: -50}}>


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div className="content-inter-info" style={{background: profileData.background_profile, width: '80%', borderRadius: 10}}>                


                <div style={{textAlign: 'center', justifyContent: 'center', display: 'flex', paddingTop: 15, zIndex: 9999999}}>
                  <p style={{width: '80%', color: profileData.color_text_profile}}>
                    {profileData.short_description}
                  </p>
                </div>

                {/* <div style={{background: '#ffffff', height: '100%', zIndex: 999999, width: '80%', position: 'absolute', opacity: 0.2}}></div> */}


                {profileData.number_whatsapp!==''&&
                  <a href={"whatsapp://send?phone=+"+profileData.profile_country+profileData.number_whatsapp}>
                      <div style={{textAlign: 'center'}}>
                          <div style={{}}>
                              <FaWhatsapp color={profileData.color_text_profile} size={25} />
                          </div>
                          <div style={{ paddingLeft: 5}}>
                            <p style={{color: profileData.color_text_profile}}>{profileData.number_whatsapp}</p>
                          </div>
                      </div>
                  </a>
                }
                
                <a href={"mailto:"+profileData.user_object.email+"?subject=Te encontré en Gridzzy.com&body=Hola "+profileData.user_object.first_name+" "+profileData.user_object.last_name+"! Quiero comunicarme contigo."}>
                  <div style={{textAlign: 'center'}}>
                      <div style={{}}>
                          <FaEnvelope color={profileData.color_text_profile} size={25} />
                      </div>
                      <div style={{ paddingLeft: 5}}>
                        <p style={{color: profileData.color_text_profile}}>{profileData.user_object.email}</p>
                      </div>
                  </div>
                </a>


                {profileData.addressCompany!==''&&
                   <div style={{textAlign: 'center'}}>
                      <div style={{}}>
                          <FaMapMarkedAlt color={profileData.color_text_profile} size={25} />
                      </div>
                      <div style={{ paddingLeft: 5}}>
                        <p style={{color: profileData.color_text_profile}}>{profileData.addressCompany}</p>
                      </div>
                  </div>
                }
               


              </div>
            </div>

   

            

                <div style={{textAlign: 'center', marginTop: 15}}>
                    <Image 
                        width="200"
                        src={profileData.url_image_code_qr}
                    />
                    {this.state.isWeb!=='false'&&
                      <div  style={{borderRadius: 50, background: profileData.color_text_profile, borderColor: profileData.background_profile, borderWidth: 1}}>
                        {imageBrochure&&
                          <a download={profileData.usernameShow} className="buttonSaveImage" href={'data:image/png;base64,'+imageBrochure} style={{borderRadius: 50, background: profileData.color_text_profile, padding: 10, borderColor: profileData.background_profile, borderWidth: 1}} >
                            <span style={{color: profileData.background_profile}}>Guardar</span>
                          </a>
                        }
                      </div>
                    }
                    <div className="showViews" style={{color: "#333333", opacity: 0.8}}>
                        <p>{profileData.views_qr} <br></br>Visualizaciones</p>
                    </div>
                </div>

    
            </div>

            {this.state.isWeb!=='false'&&
              <MenuOptionFooter is_show_modal_start={profileData.is_show_modal_start} title_ventana_emergente={profileData.title_ventana_emergente} description_ventana_emergente={profileData.description_ventana_emergente} url_image_ventana_emergente={profileData.url_image_ventana_emergente} url_image_profile={profileData.url_image_profile} username={profileData.usernameShow} background={profileData.background_profile} colorText={profileData.color_text_profile}></MenuOptionFooter>
            }
        
          </div>
        );
      }
    }

    return <Fragment>
              {content}
            </Fragment>;
  }
}

export default Profile;
