import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import LandingPage from './pages/LandingPage';
import About from './pages/About';
import Terms from './pages/Terms';
import Privacity from './pages/Privacity';

import Brochure from './pages/Brochure';
import Profile from './pages/Profile';

export default function App() {
  return (
    <Router>
      <div>
       
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/about">
            <About isApp={false}/>
          </Route>

          <Route path="/app/about">
            <About isApp={true} />
          </Route>

          <Route path="/terms">
            <Terms isApp={false}/>
          </Route>

          <Route path="/app/terms">
            <Terms isApp={true} />
          </Route>

          <Route path="/privacy">
            <Privacity isApp={false}/>
          </Route>

          <Route path="/app/privacy">
            <Privacity isApp={true}/>
          </Route>

          <Route path="/store/:user" component={Brochure}>
          </Route>

          <Route path="/profile/:user" component={Profile}>
          </Route>

          <Route path="/:user" component={Brochure}>
          </Route>

          
        </Switch>
        
      </div>
    </Router>
  );
}



