import { Link } from "react-router-dom";

import '../assets/style.css';
import Image from 'react-bootstrap/Image';

function MenuNavBar() {
    return (
        <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">    
           <Link to="/" className="logo"><Image src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/logoApp03.png" alt="" className="img-fluid" /></Link>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="getstarted scrollto" href="#contact">Contáctanos</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
    
        </div>
      </header>
    );
  }
  
  export default MenuNavBar;
  




