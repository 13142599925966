import MenuNavBar from './components/MenuNavBar';
import FooterApp from './components/FooterApp';

function About({isApp}) {
  return (
    <div className="App">
      {!isApp&&
        <MenuNavBar></MenuNavBar>
      }

        <div className="about" style={{paddingTop: !isApp?200:15, background: '#ffffff'}}>
            <div className="container">
                <h2>Acerca de Gridzzy</h2>
                <p style={{color: '#727272'}}>Nuestra misión principal es unificar tu información, haciéndole más fácil la vida a tus clientes y fans.</p>
                <p style={{color: '#727272', textAlign: 'center'}}>Versión 1.0</p>
            </div>
        </div>
        {!isApp&&
          <FooterApp></FooterApp>
        }
    </div>
  );
}

export default About;
