import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';

function FooterApp() {
    return (

      <div id="contact">
        <section className="contact">
                <div className="container">

                  <div className="section-title">
                    <span>Escríbenos</span>
                    <h2>Escríbenos</h2>
                    <p>Tu opinión es muy importante para nosotros, por eso te dejamos nuestros datos</p>
                  </div>

                  <div className="row">
                    <a href="/gridzzy">
                      <div className="col-lg-12 d-flex" style={{justifyContent: 'center'}}>
                          <Image width="250" src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/gridzzy-qr.png" className="img-fluid" alt="" />
                      </div>
                    </a>


                  </div>

                </div>
          </section>

      <footer id="footer">

      <div className="footer-top">
  
        <div className="container">
  
                <div className="row  justify-content-center">
                  <div className="col-lg-6">
                    <h3 style={{color: '#000000'}}>Gridzzy</h3>
                    <p>Instala nuestra App y consigue un mayor número de seguidores</p>

                    <div className="d-flex" style={{justifyContent: 'center', paddingTop: 20, paddingBottom: 20}}>
                      <a href="https://play.google.com/store/apps/details?id=com.gridwel.gridzzy" target="_blanck"><Image width="180" src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/google-play-badge.png"></Image></a>
                      {/* <Link to="/"><Image width="180" style={{paddingLeft: 5}} src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/apple-store-button.png"></Image></Link> */}
                    </div>
                    
                    
                  </div>
                </div>
        </div>
      </div>


  
      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; {(new Date().getFullYear())} <strong><span>Gridzzy</span>.</strong>
        </div>

        <div className="credits">
          <Link style={{textDecoration: 'underline'}} to="/terms">Términos y condiciones</Link>
          <Link style={{textDecoration: 'underline', paddingRight: 10, paddingLeft: 10}} to="/privacy">Política de Privacidad</Link>
          <Link style={{textDecoration: 'underline', paddingRight: 10, paddingLeft: 10}} to="/about">Nosotros</Link>
        </div>
      </div>

      <Link to="/" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>

    </footer>
    </div>
    );
  }
  
  export default FooterApp;
  

