import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import '../assets/brochure.css';
import Image from 'react-bootstrap/Image';
import { FaShoppingCart, FaUserAlt } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from "axios";

import {
    FaWhatsapp,
    FaYoutube,
    FaTwitter,
    FaFacebook,
    FaInstagram,
    FaGlobe,
    FaLink
} from "react-icons/fa";


import { SiNetflix, SiWechat, SiPinterest, SiTelegram, SiTwitch, SiGithub, SiMessenger, SiTinder, SiWix, SiLine, SiSnapchat, SiDiscord } from "react-icons/si";


function MenuOptionFooter({ is_show_modal_start, title_ventana_emergente, description_ventana_emergente, url_image_ventana_emergente, url_image_profile, username, background, colorText }) {

    const [show, setShow] = useState(is_show_modal_start === 'T' ? true : false);
    const handleClose = () => setShow(false);

    const [showSocial, setShowSocial] = useState(true);

    const [listSocial, setListSocial] = useState({});
    const [isLoadingListSocial, setisLoadingLListSocial] = useState(false);


    let urlBase = 'https://api.gridzzy.com';
    // let urlBase = 'http://192.168.100.224:8000';

    const getDataSocials = () => {

        axios
            .get(`${urlBase}/get-menu-grid-view-web/${username}`)
            .then((response) => {
                setListSocial({ listSocial: Object.values(response.data.socials) });
                setisLoadingLListSocial(true);
            });


        var details = {
            username: username.toString().toLowerCase(),
            type_action: 'reader_qr',
            modelObject: 'person',
            idModelObject: 0,
            description: 'Visita desde navegador web',
            device: 'movil'
        };
        
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                "Authorization": "Token 709fc5a926fe203731816df78739a87a40e6fc02"
            },
        };

        axios.post(urlBase+'/add-register-action-login/', formBody.join("&"), config);

    }

    useEffect(() => {
        if (!isLoadingListSocial) {
            getDataSocials();
        }
    });



    return (
        <div className="menu_footer" style={{ background: background }}>
            <div style={{ display: 'flex', height: '100%', justifyItems: 'center', width: '100%' }}>

                {showSocial === true &&
                    <div className="socialsDiv">
                        {(listSocial.listSocial && listSocial.listSocial.length > 0) &&
                            <div className="listaSocials" style={{ background: 'white', borderRadius: 30, padding: 10, flexDirection: 'row', overflowY: 'scroll' }}>
                                {listSocial.listSocial.map(social => {
                                    if (social.visibleLink) {
                                        return (
                                            <a href={social.urlPage} style={{ flex: 1 }} key={social.id}>

                                                <div >
                                                    {social.typeUrl_object.code === 'web' ?
                                                        <FaGlobe color={social.typeUrl_object.colorIcon} size={40} />
                                                        : social.typeUrl_object.code === 'whatsapp' ?
                                                            <FaWhatsapp color={social.typeUrl_object.colorIcon} size={40} />
                                                            : social.typeUrl_object.code === 'youtube' ?
                                                                <FaYoutube color={social.typeUrl_object.colorIcon} size={40} />
                                                                : social.typeUrl_object.code === 'twitter' ?
                                                                    <FaTwitter color={social.typeUrl_object.colorIcon} size={40} />
                                                                    : social.typeUrl_object.code === 'facebook' ?
                                                                        <FaFacebook color={social.typeUrl_object.colorIcon} size={40} />
                                                                        : social.typeUrl_object.code === 'instagram' ?
                                                                            <FaInstagram color={social.typeUrl_object.colorIcon} size={40} />
                                                                            : social.typeUrl_object.code === 'netflix' ?
                                                                                <SiNetflix color={social.typeUrl_object.colorIcon} size={40} />
                                                                                : social.typeUrl_object.code === 'wechat' ?
                                                                                    <SiWechat color={social.typeUrl_object.colorIcon} size={40} />
                                                                                    : social.typeUrl_object.code === 'pinterest' ?
                                                                                        <SiPinterest color={social.typeUrl_object.colorIcon} size={40} />
                                                                                        : social.typeUrl_object.code === 'telegram' ?
                                                                                            <SiTelegram color={social.typeUrl_object.colorIcon} size={40} />
                                                                                            : social.typeUrl_object.code === 'twitch' ?
                                                                                                <SiTwitch color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                : social.typeUrl_object.code === 'github' ?
                                                                                                    <SiGithub color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                    : social.typeUrl_object.code === 'mesenger' ?
                                                                                                        <SiMessenger color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                        : social.typeUrl_object.code === 'tinder' ?
                                                                                                            <SiTinder color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                            : social.typeUrl_object.code === 'wix' ?
                                                                                                                <SiWix color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                                : social.typeUrl_object.code === 'line' ?
                                                                                                                    <SiLine color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                                    : social.typeUrl_object.code === 'snapchat' ?
                                                                                                                        <SiSnapchat color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                                        : social.typeUrl_object.code === 'discord' ?
                                                                                                                            <SiDiscord color={social.typeUrl_object.colorIcon} size={40} />
                                                                                                                            :
                                                                                                                            <FaLink color={social.typeUrl_object.colorIcon} size={40} />
                                                    }
                                                </div>

                                                <span style={{ color: social.typeUrl_object.colorIcon }}>{social.title}</span>
                                            </a>
                                        )
                                    } else {
                                        return null;
                                    }

                                })}
                            </div>
                        }
                    </div>
                }

                <Link to={"/profile/" + username} style={{ flex: 1, background: background, width: '50%', textAlign: 'center', paddingTop: 18 }}>
                    <FaUserAlt color={colorText} size={25} />
                </Link>

                <div style={{ position: 'absolute', width: '100%', justifyContent: 'center', justifyItems: 'center', alignItems: 'center', textAlign: 'center', marginTop: -30, height: 10 }}>
                    {showSocial === true ?
                        <Button onClick={() => setShowSocial(!showSocial)} style={{ background: colorText, borderWidth: 1, color: background, width: 60, height: 60, borderRadius: 50, borderColor: background, justifyContent: 'center' }}>
                            X
                </Button>
                        :
                        <Image
                            width="100"
                            src={url_image_profile}
                            className="img-profile-menu"
                            alt=""
                            roundedCircle
                            onClick={() => { setShowSocial(!showSocial); }}
                        />
                    }
                </div>

                <Link to={"/" + username} style={{ flex: 1, background: background, width: '50%', textAlign: 'center', paddingTop: 18 }}>
                    <FaShoppingCart color={colorText} size={25} />
                </Link>

            </div>











            <Modal show={show} onHide={handleClose} style={{ zIndex: 9999999999, textAlign: 'center', paddingTop: '13%' }}>

                <Modal.Body>
                    <h2 style={{ textAlign: 'center', width: '100%', height: 50 }}>
                        {title_ventana_emergente}
                    </h2>
                    <span style={{ padding: 30 }}>
                        {description_ventana_emergente}
                    </span>
                    <br></br>

                    <Image
                        width="100%"
                        src={url_image_ventana_emergente}
                        alt=""
                        style={{ marginTop: 20 }}
                    />

                    <div style={{ position: 'absolute', top: -5, right: -5 }}>
                        <Button onClick={() => handleClose()} style={{ background: background, borderWidth: 1, color: colorText, borderRadius: 50, borderColor: colorText, justifyContent: 'center' }}>
                            X
                    </Button>
                    </div>

                </Modal.Body>
            </Modal>

        </div>
    );
}

export default MenuOptionFooter;


