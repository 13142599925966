// import { Link } from "react-router-dom";

import MenuNavBar from './components/MenuNavBar';
import FooterApp from './components/FooterApp';

import Image from 'react-bootstrap/Image';


function LandingPage() {
    return (
      <div className="App">
          <MenuNavBar></MenuNavBar>
          <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h1>Tu vida en una app</h1>
                    <h2>Haz que tu marca y tu negocio crezca con Gridzzy</h2>
                    <a href="https://play.google.com/store/apps/details?id=com.gridwel.gridzzy" target="_blanck"><Image width="180" src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/google-play-badge.png"></Image></a>
                    {/* <div className="d-flex">
                      
                      <Link to="/"><Image width="180" style={{marginLeft: 5}} src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/apple-store-button.png"></Image></Link>
                    </div> */}
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 hero-img">
                    <Image src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/hero-img.png" className="img-fluid animated" alt="" />
                  </div>
                </div>
              </div>
          </section>

              <main id="main">


              <section id="about" className="about">
                <div className="container">

                  <div className="row" style={{justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
                    <div className="col-lg-12 pt-12 pt-lg-0 content">
                      <h3>Gana Gridpoints en tus tiendas favoritas</h3>
                      <p style={{padding: 15}}>
                        Con cada compra en tu tienda favorita podrás recibir Gridpoints para redimirlos en los productos que más te gustan
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <Image width="20%" src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/online.png" className="img-fluid" alt="" />
                      <Image width="20%" src="https://images-gridzzy.s3.us-east-2.amazonaws.com/static/images/web/undraw_shopping_app_flsj.png" className="img-fluid" alt="" />
                    </div>
                  </div>

                </div>
              </section>


              <section id="cta" className="cta">
                <div className="container">

                  <div className="text-center">
                    <h3>El único enlace que necesitas</h3>
                    <p>Tu audiencia y seguidores aumentarán, centralizando tu información en una sola imagen</p>
                    {/* <a className="cta-btn" href="#">Call To Action</a> */}
                  </div>

                </div>
              </section>


             </main>
          <FooterApp></FooterApp>
      </div>
    );
  }
  
  export default LandingPage;
  